import axios from "axios";
const queryString = require('query-string');
import { jobSyncCnf } from "@/constant/config";
export default {
    getDataView(requestData) {
        let queryParams = queryString.stringify(requestData);
        return axios({
            method: "GET",
            url: `${jobSyncCnf.getDataView}?${queryParams}`,
            withCredentials: true,
        });
    },
    resyncJob(jobId) {
        return axios({
            method: "GET",
            url: `${jobSyncCnf.resyncJob}/${jobId}`,
            withCredentials: true,
        });
    },
    cancelJob(jobId) {
        return axios({
            method: "GET",
            url: `${jobSyncCnf.cancelJob}/${jobId}`,
            withCredentials: true,
        });
    },
}