import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import CaretTop from "@/views/components/Icon/CaretTop.vue";
import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
import { API_CALL_STATUS } from "@/constant/config";
import jobSyncApi from "@/api/common/jobSyncApi";

export default {

    components: {
        CaretTop,
        CaretBottom,
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance.vue"),
            loading: SkeletonBoxWithoutLoading,
        }),
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    extends: baseComponent,
    data() {
        return {
            isCheckAll: false,
            listData: [],
            isLoading: false,
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.ID',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
            },
            jobStatusMap: {
                "0": { text: "Not Init", class: "text-danger" },
                "1": { text: "Initialized", class: "" },
                "2": { text: "Scheduled", class: "text-primary" },
                "3": { text: "Processing", class: "text-primary" },
                "4": { text: "Succeeded", class: "text-success" },
                "5": { text: "Failed", class: "text-danger" },
                "6": { text: "Awaiting", class: "text-danger" },
                "7": { text: "Canceled", class: "text-danger" },
            },
            jobStepMap: {
                "0": { text: "Analyzing", class: "" },
                "1": { text: "Processing", class: "text-primary" },
                "2": { text: "Completed", class: "text-success" },
                "3": { text: "Timeout", class: "text-warning" },
                "4": { text: "Killed", class: "text-danger" },
            },
            autoLoadInterval: null,
        };
    },

    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            listStaff: state => state.staffs,
            decentralization: state => state.auth.decentralization,
        })
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);
        this.getData();
    },

    mounted() {
        this.autoLoadInterval = setInterval(() => {
            this.getData();
        }, 30000);
    },
    beforeDestroy() {
        if (this.autoLoadInterval) {
            clearInterval(this.autoLoadInterval);
        }
    },
    methods: {
        getData() {
            this.isLoading = true;
            jobSyncApi.getDataView({
                querySearch: this.pagination.querySearch,
                pageIndex: this.pagination.pageIndex,
                pageSize: this.pagination.pageSize,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
            }).then((res) => {
                try {
                    if (res.data.result === 0) {
                        this.$set(this, 'listData', res.data.data.data);
                        this.$set(this, 'pagination', {
                            ...this.pagination,
                            status: 1,
                            orderBy: res.data.data.pagingItem.orderBy,
                            directionSort: res.data.data.pagingItem.directionSort,
                            pageIndex: res.data.data.pagingItem.pageIndex,
                            pageSize: res.data.data.pagingItem.pageSize,
                            outRowsNumber: res.data.data.pagingItem.outRowsNumber,
                        });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    }
                } catch (error) {
                    console.log("getDataView -> error", error);
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log("getDataView -> error", error);
                this.feedStatusLoading(API_CALL_STATUS.FAILED);
                this.isLoading = false;
            });
        },
        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },
        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },
        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
                case 'add':
                    this.addItem();
                    break;
                case 'delete':
                    this.deleteItem(param.data.id);
                    break;
            }
        },
        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },
        resyncJob(jobItem) {
            this.$confirm(`This will permanently resync job <strong>${jobItem.jobId}</strong>. Continue?`,
                `Resync job ${jobItem.jobId}`,
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.isLoading = true;               
                    jobSyncApi.resyncJob(jobItem.jobId).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.getData();
                            }
                        } catch (error) {
                            console.log("resyncJob -> error", error);
                        }
                        this.isLoading = false;
                    }).catch(error => {
                        console.log("resyncJob -> error", error);
                        this.isLoading = false;
                    });
                }).catch(() => { });
        },
        cancelJob(jobItem) {
            this.$confirm(`This will permanently cancel sync job <strong>${jobItem.jobId}</strong>. Continue?`,
                `Cancel job ${jobItem.jobId}`,
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.isLoading = true;
                    jobSyncApi.cancelJob(jobItem.jobId).then((res) => {
                        try {
                            if (res.data.result === 0) {
                                this.getData();
                            }
                        } catch (error) {
                            console.log("cancelJob -> error", error);
                        }
                        this.isLoading = false;
                    }).catch(error => {
                        console.log("cancelJob -> error", error);
                        this.isLoading = false;
                    });
                }).catch(() => { });
        },
    }
}